<!--理赔考试登录二维码-->
<template>
  <div style="width: 100%;">
    <a-spin tip="加载中..." :spinning="spinning">
      <a-row type="flex" justify="center" style="height: 10px"></a-row>
      <a-row type="flex" justify="center" v-if="expirationTime">二维码过期时间：<span style="font-weight:bold;color: #F56C6C">{{expirationTime}}</span></a-row>
      <a-row type="flex" justify="center" style="margin-top: 18px">
        <a-card hoverable style="width: 260px;">
          <img
              slot="cover"
              alt="example"
              style='display:block; width:260px;height:260px;' id='base64image'
              :src="qrCodeBase64"
          />
        </a-card>
      </a-row>
      <a-row style="margin-top: 10px"><a-col style="font-size: larger; font-weight:bold">请用交管12123软件里“扫一扫”扫描二维码</a-col></a-row>
      <a-row style="margin-top: 10px; font-size: larger" v-if="claimInfo">
        <a-row type="flex" justify="center" :gutter="16" style="margin: 10px">
          <a-col>
            <p>姓名</p>
            <p>证件号</p>
            <p>考试科目</p>
            <p>考试时间</p>
          </a-col>
          <a-col>
            <p>{{ claimInfo.insuredName }}</p>
            <p>{{ claimInfo.insuredCardNo }}</p>
            <div style="width: 200px;border: solid 1px #F56C6C">
              <p>{{ 2==claimInfo.driveReportVo.claimDriveType?'重学':claimSubjectMap[claimInfo.driveReportVo.subject] }}</p>
              <p>{{ claimInfo.driveReportVo.examTime }}</p>
            </div>
            <p style="color:#F56C6C;">请确定挂科科目和日期</p>
          </a-col>
        </a-row>
      </a-row>
      <a-row>
        <a-col style="font-size: large; font-weight:bold; color: #F56C6C">
          此二维码有效期为3分钟请尽快扫码
      </a-col>
      </a-row>
      <a-row style="margin-top: 10px"><a-col style="font-size: larger; font-weight:bold">我司客服电话：13382042898（微信同号）</a-col></a-row>
      <a-row style="margin-top: 16px">
        <a-col>
          <a-button type="primary" @click="copyUrl">点击复制链接地址</a-button>
        </a-col>
      </a-row>

    </a-spin>
  </div>
</template>
<script>
import {getLoginQrCodeImg, getQueryQRCode} from "@/api/claim";
import {Dialog, Notify} from "vant";

export default {
  name: 'ClaimExamQr',
  data() {
    return {
      spinning: false,
      claimCode: '',
      qrCodeBase64: '',
      token: '',
      queryQRCodeTime: null,
      expirationTime: null,
      claimInfo: {
        insuredName: '',
        insuredCardNo: '',
        driveReportVo: {
          claimDriveType: "",
          subject: null,
          examTime: ""
        }
      },
      claimSubjectMap:{
        1:'科目一',
        2:'科目二',
        3:'科目三',
        4:'科目四'
      },
      requestCount: 0,
    }
  },
  mounted() {
    this.loginQrCodeImg();
  },
  methods: {
    loginQrCodeImg() {
      this.spinning = true;
      this.claimCode = this.$route.query.claimCode;
      getLoginQrCodeImg({claimCode: this.claimCode}).then(res => {
        this.spinning = false;
        if (200 === res.code && res.data) {
          if (res.data.token) {
            this.qrCodeBase64 = 'data:image/jpeg;base64, ' + res.data.img;
            this.token = res.data.token;
            this.expirationTime = res.data.expirationTime;
            this.claimInfo = res.data.claimInfo;
            this.queryQRCodeTime = setInterval(this.queryQRCode, 4000);
            this.requestCount += 1;
          } else {
            var tipss = '授权二维码获取失败[' + (res.data.msg?res.data.msg:"未知错误") + ']，请稍后点击重新获取！';
            Dialog.alert({
              title: '提示',
              message: tipss,
            }).then(() => {
              this.loginQrCodeImg();
            }).catch(() => {
              // on cancel
            });
          }
        } else {
          // Notify({type: 'danger', message: res.message?res.message:"信息错误"});
          var tips = '授权二维码获取失败[' + (res.message?res.message:"未知错误") + ']，请点击重新获取！';
          Dialog.alert({
            title: '提示',
            message: tips,
          }).then(() => {
            this.loginQrCodeImg();
          }).catch(() => {
            // on cancel
          });
        }
      }).catch(()=>{
        this.spinning = false;
        // Notify({type: 'danger', message: "接口请求失败，请刷新重新页面！"});
        Dialog.alert({
          title: '提示',
          message: '接口请求失败，请点击重新获取！',
        }).then(() => {
          this.loginQrCodeImg();
        });
      }).finally(()=>{
        this.spinning = false;
      });
    },
    queryQRCode() {
      console.log("queryQRCode start");
      getQueryQRCode({claimCode: this.claimCode, token: this.token}).then(res => {
        if (200 === res.code) {
          if ('200' == res.data.code) {
            clearInterval(this.queryQRCodeTime);
            this.queryQRCodeTime = null;
            Dialog.alert({
              title: '提示',
              message: '授权成功，理赔进度您可通过“我的理赔”功能进行查询',
            }).then(() => {
              // on close
              this.$router.push("/claim/policySelect");
            });
          } else if ('204' == res.data.code
              || '400' == res.data.code) {
            clearInterval(this.queryQRCodeTime);
            this.queryQRCodeTime = null;
            if (this.requestCount > 3) {
              Dialog.alert({
                title: '提示',
                message: '授权失败，请重新授权！',
              }).then(() => {
                this.$router.push("/claim/examSelect")
              });
            } else {
              var msg = res.data.msg;
              if (msg.indexOf('失效') !== -1) {
                //token已失效 改进 二维码
                var changeMsg = msg.replace("token", "二维码")
                Dialog.alert({
                  title: '提示',
                  message:  changeMsg + '，请点击重新获取',
                }).then(() => {
                  this.loginQrCodeImg();
                });
              } else {
                Dialog.alert({
                  title: '提示',
                  message: '授权失败【正在修复中】，请稍后再试或者手动上传考试截图',
                }).then(() => {
                  this.$router.push({path: '/claim/claimInsert', query: {claimCode: this.claimCode}});
                });
              }
            }
          } else if ('555' == res.data.code) {
            clearInterval(this.queryQRCodeTime);
            this.queryQRCodeTime = null;
            Dialog.alert({
              title: '提示',
              message: '授权失败，请手动上传考试截图',
            }).then(() => {
              this.$router.push({path: '/claim/claimInsert', query: {claimCode: this.claimCode}});
            });
          } else {
            console.log(res.data);
          }
        }else {
          Notify({type: 'danger', message: res.message?res.message:"信息错误"});
        }
      }).catch(()=>{
        Notify({type: 'danger', message: "接口请求失败，请刷新重新页面！"});
      }).finally(()=>{
      });
    },

    copyUrl() {
      // 复制到粘贴板
      const input = document.createElement('input')
      input.setAttribute('readonly', 'readonly')
      let url= window.location.href
      input.setAttribute('value', url)
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        Notify({type: 'success', message: "链接地址已复制！"});
      }
      document.body.removeChild(input)
    }

  },
  // 在离开这个页面的时候，（比如路由跳转到别的页面）必须要清除一下这个定时器，定时器不手动清除的话，会一直存在并执行
  // 直到我们的项目服务停掉，或者关机。所以适当时候清除定时器是非常有必要的。不然耗费性能，且容易造成代码混乱
  beforeDestroy() {
    // js提供的clearInterval方法用来清除定时器
    clearInterval(this.queryQRCodeTime);
    this.queryQRCodeTime = null;
  },
}

</script>
